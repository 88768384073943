<template>
  <div class=container>
      <table width="100%" border="0" cellspacing="30" cellpadding="0">
          <tbody><tr>
            <td><div align="center"><font size="+2">Video-Rate Direct-Global Separation</font></div></td>
          </tr>
          <tr>
            <td>Here, we show several examples of our separation results at video-rate. In all cases, the scene image is the sum of the computed direct and global images. However, for some of the scenes, we have brightened the direct and global images by a scale factor (between 1 and 2) with respect to the scene image to bring out the details. In each case, we mention the separation method we have used. <br><strong>The videos can be viewed by clicking on each of the thumbnail images shown below.</strong>
<br><router-link :to="{path:'/projects/categories/project', query:{cid:'Computational Imaging',pid:'Fast Separation of Direct and Global Images'}}">(Back to Main Project Page) </router-link></td>
          </tr>

          <tr>
            <td><table width="100%" border="1" cellspacing="0" cellpadding="0">
              <tbody><tr>
                <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                    <tbody><tr>
                      <td width="47%"><strong>Ball in Diffuse Box</strong></td>
                      <td width="53%"><div align="right"><strong>Method : Stripes<br>
                      </strong></div></td>
                    </tr>
                  </tbody></table>
                    <div align="right"></div></td>
              </tr>

              <tr>
                <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                    <tbody><tr>
                      <td width="27%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/videos/diffuseBox.wmv"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/thumbBox.png" width="160" height="150" border="0"></a><br>
                        </div></td>
                      <td width="73%">This scene includes a diffuse, white colored ball moving around inside a brightly painted diffuse box (similar to the well known Cornell box). When the ball is at the center of the box, it receives uniform global illumination from the four walls, and hence the global image shows the ball being equally colored orange and green. However, when the ball moves closer to one of the walls, there is greater color bleeding due to stronger interreflections. In contrast, the ball remains white in the direct image for the entire duration and clearly shows only the diffuse shading.</td>
                    </tr>
                  </tbody></table>
                  </td>
              </tr>

            </tbody></table></td>
          </tr>

           <tr>
            <td><table width="100%" border="1" cellspacing="0" cellpadding="0">
              <tbody><tr>
                <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                    <tbody><tr>
                      <td width="47%"><strong>Fish in Milky Water</strong></td>
                      <td width="53%"><div align="right"><strong>Method : Stripes<br>
                      </strong></div></td>
                    </tr>
                  </tbody></table>
                    <div align="right"></div></td>
              </tr>
              <tr>
                <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                    <tbody><tr>
                      <td width="27%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/videos/fishTank.wmv"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/thumbFish.png" width="160" height="150" border="0"></a><br>
                        </div></td>
                      <td width="73%">In underwater imaging, the scattering of light by the murky water usually degrades the quality of captured images. In this example, we try to emulate this scenario and apply the separation method. Here, the scene consists of a glass tank filled with milky water and a plastic fish moving around in front of a coral reef background. The global effects are mainly due to volumetric scattering by the milky medium, which not only scatters light from the source but also illuminates the objects as a secondary source. Note that the direct image is devoid of any volumetric scattering and hence appears like fish moving around in a clear medium.
</td>
                    </tr>
                  </tbody></table>
                  </td>
              </tr>

            </tbody></table></td>
          </tr>



          <tr>
            <td>&nbsp;</td>
          </tr>
        </tbody></table>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
}
</style>